import { randomInt } from "../util/numbers";

const words = [
  "vuosi",
  "markka",
  "maa",
  "asia",
  "prosentti",
  "ihminen",
  "mies",
  "aika",
  "työ",
  "mieli",
  "kaupunki",
  "osa",
  "alue",
  "lapsi",
  "päivä",
  "hallitus",
  "kunta",
  "nainen",
  "viikko",
  "luku",
  "kerta",
  "yritys",
  "alku",
  "paikka",
  "tilanne",
  "presidentti",
  "tieto",
  "loppu",
  "tapa",
  "maailma",
  "määrä",
  "puheenjohtaja",
  "poliisi",
  "kisa",
  "auto",
  "toiminta",
  "tulos",
  "raha",
  "päätös",
  "käyttö",
  "syy",
  "elämä",
  "kilpailu",
  "koulu",
  "kesä",
  "ongelma",
  "yhtiö",
  "joukkue",
  "nuori",
  "mahdollisuus",
  "kausi",
  "suomalainen",
  "valtio",
  "kuva",
  "nimi",
  "joukko",
  "johtaja",
  "tila",
  "apu",
  "ryhmä",
  "hetki",
  "tehtävä",
  "puolue",
  "tavoite",
  "voitto",
  "matka",
  "kauppa",
  "ottelu",
  "sopimus",
  "maanantai",
  "hinta",
  "sunnuntai",
  "sarja",
  "perjantai",
  "puoli",
  "lauantai",
  "yhteistyö",
  "jäsen",
  "tutkimus",
  "syksy",
  "torstai",
  "tiistai",
  "keskiviikko",
  "kysymys",
  "talo",
  "kuukausi",
  "ohjelma",
  "ratkaisu",
  "peli",
  "kirja",
  "tuki",
  "poika",
  "esitys",
  "edustaja",
  "ala",
  "tie",
  "perhe",
  "metri",
  "oikeus",
  "asema",
  "elokuva",
  "voima",
  "tarkoitus",
  "maali",
  "johto",
  "muutos",
  "tunti",
  "tapahtuma",
];

export function getNewWord(): string {
  const idx = randomInt(0, words.length);
  return words[idx].toLocaleUpperCase();
}
