export const HangmanImages = [
  "01-kumpu",
  "02-tolppa",
  "03-alatuki-vasen",
  "04-alatuki-oikea",
  "05-ylapalkki",
  "06-ylatuki",
  "07-koysi",
  "08-paa",
  "09-vartalo",
  "10-jalka-vasen",
  "11-jalka-oikea",
  "12-kasi-vasen",
  "13-kasi-oikea",
  "14-naama",
];
